import React, { useEffect, useRef } from "react"

import { graphql } from "gatsby"
// import Layout from "@/components/layout"
import imageUrlBuilder from "@sanity/image-url"
import clientConfig from "../../client-config"
// import { PortableText } from "@portabletext/react"
import PortableTextBlock from "../components/portableText"

// import { toPlainText } from "@portabletext/react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Seo from "../components/seo"
import { BrowserView, MobileView } from "react-device-detect"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation } from "swiper"
import RandomCharacterArt from "../components/RandomCharacterArt"

import FontDemo from "../components/FontDemo"

import "./merchandise.css"

export const query = graphql`
  query MerchTemplateQuery($id: String!) {
    merch: sanityMerchandise(id: { eq: $id }, _id: { glob: "!drafts*" }) {
      id
      productType
      digitalType
      cdnLink
      mainImage {
        asset {
          url
        }
        hotspot {
          x
          y
        }
      }
      price
      quantity
      shippingPrice
      guid
      cdnLink
      cssFont
      subtitle
      excerpt
      sku
      weight
      taxable
      shippable
      localDeliveryOnly
      title
      showLink
      link {
        title
        linkUrl
        external
        link {
          ... on SanityProducts {
            id
            _type
            slug {
              current
            }
            excerpt
          }
          ... on SanityPost {
            id
            title
            _type
            slug {
              current
            }
            excerpt
          }
          ... on SanityPages {
            id
            title
            _type
            slug {
              current
            }
            excerpt
          }
          ... on SanityCategory {
            id
            title
            _type
          }
        }
      }
      linkSlide {
        url
        title
        mobileImage {
          hotspot {
            y
            x
          }
          asset {
            url
          }
        }
        desktopImage {
          hotspot {
            y
            x
          }
          asset {
            url
          }
        }
      }
      imageGallery {
        linkUrl
        title
        isLink
        alt
        asset {
          url
        }
        hotspot {
          x
          y
        }
      }
      slug {
        current
      }
      features {
        title
        parameter
      }
      _rawBody
      _id
    }
  }
`

const MerchTemplate = props => {
  const { data } = props
  const merch = data && data.merch

  // font license user adjustment
  const button = useRef(null)
  const select = useRef(null)

  useEffect(() => {
    if (merch.productType === "digital") {
      select.current.addEventListener("change", () => {
        button.current.setAttribute(
          "data-item-custom2-value",
          select.current.value
        )
      })
    }
  }, [])

  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }

  var slideDirections = ["left", "right", "up", "down"]
  var randomValueDesktop =
    slideDirections[Math.floor(Math.random() * slideDirections.length)]
  var slideDirections2 = ["up", "down"]
  var randomValueMobile =
    slideDirections2[Math.floor(Math.random() * slideDirections2.length)]

  return (
    <div className="bg-white dark:bg-zinc-800 text-zinc-800 dark:text-zinc-300  md:pb-10">
      <Seo
        title={merch.title + " - Budmen"}
        description={merch.excerpt}
        defer={false}
        image={urlFor(merch.mainImage)
          .width(1200)
          .height(628)
          .auto("format")
          .url()}
        twitterImage={urlFor(merch.mainImage)
          .width(1200)
          .height(628)
          .auto("format")
          .url()}
      />
      <div className="text-zinc-500 pt-2 px-4 text-[0.625rem] mb-2">
        <a className="text-zinc-400" href="/store">
          Store
        </a>{" "}
        / {merch.slug.current}
      </div>

      <section className="w-full mx-auto px-4 flex lg:flex-row flex-col gap-6">
        <section className="lg:w-3/5 w-full mx-auto">
          <BrowserView>
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination, Navigation]}
              className="mySwiper"
              navigation={true}
              loop={true}
            >
              {merch.imageGallery.length >= 1 &&
                merch.imageGallery.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      alt={image.alt !== null ? image.alt : "Gallery image"}
                      className=" mx-auto mb-6"
                      src={urlFor(image)
                        .focalPoint(
                          image.hotspot ? image.hotspot.x : "0.5",
                          image.hotspot ? image.hotspot.y : "0.5"
                        )
                        .height(1260)
                        .width(1920)
                        .crop("focalpoint")
                        .fit("crop")
                        .auto("format")
                        .url()}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </BrowserView>
          <MobileView>
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination, Navigation]}
              className="mySwiper flex h-full"
              navigation={true}
            >
              {merch.imageGallery.length >= 1 &&
                merch.imageGallery.map((image, index) => (
                  <SwiperSlide
                    key={index}
                    className="flex items-center align-middle content-center justify-center"
                  >
                    <img
                      alt={
                        image.alt !== null ? image.alt : "Mobile Gallery image"
                      }
                      className="  mx-auto  mb-6"
                      src={urlFor(image)
                        .height(1260)
                        .width(1920)
                        .focalPoint(
                          image.hotspot ? image.hotspot.x : "0.5",
                          image.hotspot ? image.hotspot.y : "0.5"
                        )
                        .crop("focalpoint")
                        .fit("crop")
                        .auto("format")
                        .url()}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </MobileView>
        </section>
        <section className="lg:w-2/5 w-full mx-auto md:p-3 px-3 md:text-left text-center">
          <h1 className="font-light text-xs uppercase tracking-widest mb-0">
            {merch.subtitle}
          </h1>
          <h1 className="font-bold uppercase text-xl tracking-widest mb-0">
            {merch.title}
          </h1>
          {merch.productType === "physical" && (
            <h1 className="font-medium text-lg mb-4">${merch.price}</h1>
          )}
          {merch.quantity === 0 && (
            <div
              class="bg-red-100 dark:bg-rose-900 dark:text-rose-300 dark:border-red-900 border text-xs border-red-400 text-red-700 px-2 py-1 rounded relative"
              role="alert"
            >
              <strong class="font-bold">Out of stock! </strong>
              <span class="block sm:inline">Check back soon to order.</span>
            </div>
          )}
          {merch.productType === "digital" && (
            <div className="mt-4">
              <select
                ref={select}
                id="commercial_license"
                className=" dark:bg-zinc-900 mb-4  md:text-base text-xs"
              >
                <option value="1-5 User">
                  1-5 User Commercial License ($29.00)
                </option>
                <option value="6-10 User">
                  6-10 User Commercial License ($50.75)
                </option>
                <option value="11-15 User">
                  11-15 User Commercial License ($58.00)
                </option>
                <option value="16-20 User">
                  16-20 User Commercial License ($69.60)
                </option>
                <option value="21-25 User">
                  21-25 User Commercial License ($84.10)
                </option>
                <option value="26-30 User">
                  26-30 User Commercial License ($98.60)
                </option>
                <option value="31-35 User">
                  31-35 User Commercial License ($110.20)
                </option>
                <option value="36-40 User">
                  36-40 User Commercial License ($124.70)
                </option>
                <option value="41-45 User">
                  41-45 User Commercial License ($136.30)
                </option>
                <option value="46-50 User">
                  46-50 User Commercial License ($150.80)
                </option>
                <option value="51-55 User">
                  51-55 User Commercial License ($162.40)
                </option>
                <option value="56-60 User">
                  56-60 User Commercial License ($176.90)
                </option>
                <option value="61-65 User">
                  61-65 User Commercial License ($188.50)
                </option>
                <option value="66-70 User">
                  66-70 User Commercial License ($200.10)
                </option>
                <option value="71-75 User">
                  71-75 User Commercial License ($214.60)
                </option>
                <option value="76-80 User">
                  76-80 User Commercial License ($226.20)
                </option>
                <option value="81-85 User">
                  81-85 User Commercial License ($240.70)
                </option>
                <option value="86-90 User">
                  86-90 User Commercial License ($252.30)
                </option>
                <option value="91-95 User">
                  91-95 User Commercial License ($266.80)
                </option>
                <option value="96-100 User">
                  96-100 User Commercial License ($278.40)
                </option>
              </select>

              <button
                ref={button}
                className="snipcart-add-item disabled:opacity-90 disabled:hover:opacity-100 disabled:cursor-not-allowed flex-shrink-0 text-white mb-4 bg-purple-500 border-0 py-2 px-4 focus:outline-none hover:bg-purple-600 rounded font-bold tracking-widest text-xs uppercase md:mt-4 mt-0"
                data-item-id={merch.sku}
                data-item-price={merch.price}
                data-item-url={
                  "https://www.budmen.com/store/" + merch.slug.current
                }
                data-item-description={merch.excerpt}
                data-item-image={
                  merch.mainImage &&
                  urlFor(merch.mainImage).auto("format").url()
                }
                data-item-name={merch.title}
                data-item-max-quantity="1"
                data-item-custom1-name="Commercial License"
                data-item-custom1-options="1-5 User| 6-10 User[+21.75]|11-15 User[+29.00]|16-20 User[+40.60]|21-25 User[+55.10]|26-30 User[+69.60]|31-35 User[+81.20]|36-40 User[+95.70]|41-45 User[+107.30]|46-50 User[+121.80]|51-55 User[+133.40]|56-60 User[+147.90]|61-65 User[+159.50]|66-70 User[+171.10]|71-75 User[+185.60]|76-80 User[+197.20]|81-85 User[+211.70]|86-90 User[+223.30]|91-95 User[+237.80]| 96-100 User[+249.40]"
                data-item-custom1-value="1-5 Users"
                data-item-file-guid={merch.guid}
              >
                Add to cart
              </button>
            </div>
          )}
          {merch.productType === "physical" && (
            <button
              className="snipcart-add-item disabled:opacity-90 disabled:hover:opacity-100 disabled:cursor-not-allowed flex-shrink-0 text-white mb-4 bg-purple-500 border-0 py-2 px-4 focus:outline-none hover:bg-purple-600 rounded font-bold tracking-widest text-xs uppercase md:mt-4 mt-0"
              data-item-id={merch.sku}
              data-item-price={merch.price}
              data-item-url={
                "https://www.budmen.com/store/" + merch.slug.current
              }
              data-item-description={merch.excerpt}
              data-item-image={
                merch.mainImage && urlFor(merch.mainImage).auto("format").url()
              }
              data-item-name={merch.title}
              data-item-quantity="1"
              data-item-weight={merch.weight}
              data-item-taxable={merch.taxable}
              data-item-shippable={merch.shippable}
              data-item-custom1-name="Local delivery only"
              data-item-custom1-value={
                merch.localDeliveryOnly ? "true" : "false"
              }
              // data-item-taxes={tva}
              disabled={merch.quantity === 0 ? true : false}
            >
              Add to cart
            </button>
          )}
          <div className="text-sm">
            <PortableTextBlock blocks={merch._rawBody} />
          </div>
          {merch.features.length !== 0 && (
            <table className="w-full text-xs text-left dark:text-zinc-200 mb-4 border-zinc-300">
              <thead className="text-[0.55rem] tracking-widest uppercase border-b border-zinc-300 dark:border-white  dark:text-zinc-200">
                <tr>
                  <th colSpan="2" className="py-3">
                    TECHNICAL SPECIFICATIONS
                  </th>
                </tr>
              </thead>
              <tbody>
                {merch.features.map((stat, index) => (
                  <tr
                    className="border-b border-zinc-300 dark:border-white"
                    key={index}
                  >
                    <th
                      scope="row"
                      className="py-3 font-medium whitespace-nowrap"
                    >
                      {stat.title}
                    </th>
                    <td className="py-3">{stat.parameter}</td>
                  </tr>
                ))}
              </tbody>
              <colgroup>
                <col span="1" style={{ width: "25%" }}></col>
                <col span="1" style={{ width: "75%" }}></col>
              </colgroup>
            </table>
          )}
          {merch.showLink === true && (
            <div className={merch.showLink ? "block mb-4" : "hidden"}>
              <BrowserView>
                <AniLink
                  cover
                  duration={3}
                  direction={randomValueDesktop}
                  className=" text-sm mt-10 text-teal-500 hover:underline flex items-center"
                  to="/"
                  bg={
                    "url(" +
                    urlFor(merch.linkSlide.desktopImage).auto("format").url() +
                    ")center / cover no-repeat fixed padding-box content-box white"
                  }
                >
                  {merch.link.title}
                </AniLink>
              </BrowserView>
              <MobileView>
                <AniLink
                  cover
                  duration={3}
                  direction={randomValueMobile}
                  className="text-sm mt-10 text-teal-500  hover:underline flex"
                  to="/"
                  bg={
                    "url(" +
                    urlFor(merch.linkSlide.mobileImage).auto("format").url() +
                    ")center / contain no-repeat fixed padding-box content-box white"
                  }
                >
                  {merch.link.title}
                </AniLink>
              </MobileView>
            </div>
          )}
        </section>
      </section>
      {merch.productType === "digital" && (
        <>
          <section className="flex md:flex-row flex-col md:mt-0 mt-6">
            <div className="md:w-1/2 w-full px-2 pb-2 md:pl-4">
              <div className="p-2">
                <FontDemo
                  font={merch.cssFont}
                  fontUrl={merch.cdnLink}
                  exampleWord={"WINGADINGDIGIT"}
                ></FontDemo>
              </div>
              <h1 className="font-light text-xs uppercase tracking-widest mb-0 border-t-2 pt-4 border-purple-200">
                CHARACTERS
              </h1>
              <div className="w-full  md:text-7xl text-5xl grid grid-cols-4 gap-2 items-center contraption">
                <span className="contraption flex items-center justify-left">
                  A
                </span>
                <span className="contraption flex items-center justify-left">
                  B
                </span>
                <span className="contraption flex items-center justify-left">
                  C
                </span>
                <span className="contraption flex items-center justify-left">
                  D
                </span>
                <span className="contraption flex items-center justify-left">
                  E
                </span>
                <span className="contraption flex items-center justify-left">
                  F
                </span>
                <span className="contraption flex items-center justify-left">
                  G
                </span>
                <span className="contraption flex items-center justify-left">
                  H
                </span>
                <span className="contraption flex items-center justify-left">
                  I
                </span>
                <span className="contraption flex items-center justify-left">
                  J
                </span>
                <span className="contraption flex items-center justify-left">
                  K
                </span>
                <span className="contraption flex items-center justify-left">
                  L
                </span>
                <span className="contraption flex items-center justify-left">
                  M
                </span>
                <span className="contraption flex items-center justify-left">
                  N
                </span>
                <span className="contraption flex items-center justify-left">
                  O
                </span>
                <span className="contraption flex items-center justify-left">
                  P
                </span>
                <span className="contraption flex items-center justify-left">
                  Q
                </span>
                <span className="contraption flex items-center justify-left">
                  R
                </span>
                <span className="contraption flex items-center justify-left">
                  S
                </span>
                <span className="contraption flex items-center justify-left">
                  T
                </span>
                <span className="contraption flex items-center justify-left">
                  U
                </span>
                <span className="contraption flex items-center justify-left">
                  V
                </span>
                <span className="contraption flex items-center justify-left">
                  W
                </span>
                <span className="contraption flex items-center justify-left">
                  X
                </span>
                <span className="contraption flex items-center justify-left">
                  Y
                </span>
                <span className="contraption flex items-center justify-left">
                  Z
                </span>
              </div>
            </div>
            <div className="md:w-1/2 w-full">
              <h1 className="font-light text-xs uppercase tracking-widest my-2 border-purple-200 md:pl-0 pl-2">
                CHARACTER ART
              </h1>
              <RandomCharacterArt></RandomCharacterArt>
            </div>
          </section>
        </>
      )}
    </div>
  )
}

export default MerchTemplate
